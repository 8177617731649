import React from "react";
import InfoContactUsForm from "../../components/info-contact-us-form";
import Layout from "../../components/layout";
import Quote from "../../components/quote";
import {navigate} from "gatsby";


const subscriberLpHowToConvinceYourBoss = process.env.GATSBY_SUBSCRIBER_LP_HOW_TO_CONVINCE_YOUR_BOSS_FORM_TAG;


const Main = ({location}) => (
  <section className="container-xl p-0">
    <div className="row m-auto">
      <div className="col-12 col-md-6 py-5 pr-md-5 py-md-6">
        <h1>
          Convince Your Boss to Invest in a Web Design Consultant
        </h1>
        <h3>
          Use This Framework to Impress Your Leadership and Secure the Budget for Your Next Project
        </h3>
        <p>
          Your website is not a living, breathing employee… but it is the only thing that can work for you 24/7 with no breaks or vacations. If you're reading this, then you already understand how important good web design is for your business.
        </p>
        <p>
          It can be difficult to convince your boss to invest in a web design consultant to lead the charge for development and maintenance of an effective website.
        </p>
        <h5>But Not to Worry!</h5>
        <p>
          We’re here to help you learn to speak your boss’s language and effectively build the case for investing in a web design consultant for your project.
        </p>
        <h4>Key Takeaways:</h4>

        <div className="row">
          <div className="col-lg-6">
            <p>Determine the goals of your website in your business context</p>
            <p>Identify why quality design is (or should be) priority for your leadership</p>
          </div>
          <div className="col-lg-6">
            <p>Demonstrate where your website is underperforming</p>
            <p>Demonstrate how a web design consultant actually saves you money and adds to your bottom line</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 bg-primary py-6 px-lg-6">
        <h4 className="text-white text-center">
          Let us help you prove the ROI of good web design to your decision maker.
        </h4>
        <p className="text-white text-center">
          Grab our free guide, How to Convince your Boss to Invest in a Web Design Consultant, by entering your email below. This three part framework will provide you with concrete guidance that’ll have you persuading your leaders in no time.
        </p>
        <div className="bg-light p-4 bg-light mt-3">
          <InfoContactUsForm
            location={location}
            tags={[subscriberLpHowToConvinceYourBoss]}
            onSubmitSuccess={()=> navigate('../../thank-you-download-how-to-convince-your-boss-web-design-consultant-roi')}
            buttonText="Submit" />
        </div>
      </div>
    </div>
  </section>
);

const Testimonial = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 align-self-center">
          <h2>
            What our clients are saying about our web design work

          </h2>
        </div>
        <div className="col-12 col-md-6">
          <Quote
            quote="We appreciate all of you so much. Your work and support is top notch."
            name="JILLIAN JANISON"
            company="PAWC"
          />
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => (
  <Layout>
    <Main location={location}/>
    <Testimonial />
  </Layout>
);

export default Index;
